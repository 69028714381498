@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-72);
	max-width: 1440px;
	overflow: hidden;
	background-color: var(--color-dark-blue-white97);
}

@media (min-width: $breakpoint-xl) {
	.container {
		flex-direction: row;
	}
}

.cards_container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
	width: var(--grid-columns-12);
}

.link {
	p {
		font-weight: var(--font-weight-semi-bold);
	}
}

.button {
	display: flex;
	flex: 1 1;
	align-items: stretch;
	width: 100%;
	height: 100%;
	padding: 0;
	text-align: left;
	background: transparent;
}

.empty_state_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 240px;
	padding: 0 var(--responsive-spacing-24);
	text-align: center;
	border: 2px dashed rgba(0, 32, 57, 0.25);
	border-radius: 8px;

	@media (min-width: $breakpoint-sm) {
		font-size: var(--font-size-18);
	}
}

.swiper {
	position: relative;
	align-items: flex-end;
	width: var(--grid-columns-12);
	height: 100%;
	overflow: visible;

	.swiper_item {
		display: flex;
		flex-direction: column;
		gap: var(--size-4);
		width: var(--grid-columns-2);
		height: auto;
		margin-right: var(--size-4);
		hyphens: auto;
	}
}
