@use 'libs/theme' as *;

.green {
	background-color: var(--color-signal-green70);
}

.yellow {
	background-color: var(--color-signal-yellow70);
}

.red {
	background-color: var(--color-signal-red70);
}

.job_search_assessment_status_banner {
	display: flex;
	gap: var(--size-4);
	align-items: center;
	padding: var(--size-4);
}
