@use 'libs/theme' as *;

.container {
	display: flex;
	flex-direction: column;
	gap: var(--responsive-spacing-36);
	max-width: 1440px;
}

.tasks_container {
	display: flex;
	flex-direction: column;
	gap: var(--size-1);
}

.empty_state_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 240px;
	padding: 0 var(--responsive-spacing-24);
	text-align: center;
	border: 2px dashed rgba(0, 32, 57, 0.25);
	border-radius: 8px;

	@media (min-width: $breakpoint-sm) {
		font-size: var(--font-size-18);
	}
}
